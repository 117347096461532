import { useContext, useState } from "react";
import { Column, Row } from "../containers/flexbox.styled";
import { useNavigate } from "react-router-dom";
import { DedicatedCoinContext } from "../../context";
import { PrimaryButton } from "../buttons/buttons.styled";
import AmountInput from "../compareSection/amountInput";
import { defaultCoins } from "../compareSection/defaultCoins";
import Dropdown from "../compareSection/dropdown";
import { StyledShortBoxContentWrapper } from "../misc/shortBoxContentWrapper.styled";
import { LightHeader, SmallText } from "../texts.styled";
import { toast } from "react-toastify";
import { sendCompareButtonClick } from "../stream";
import { borderRads } from "../sizes";
import { theme } from "../theme.styled";
import RoiBattleDropdown from "./roiBattleDropdown";

export default function CompareWith({ isFake, hasTags }) {
  const navigate = useNavigate();

  const coinData = useContext(DedicatedCoinContext);

  const [coin, setCoin] = useState(defaultCoins.bitcoin);
  const [amount, setAmount] = useState("");
  const [amountType, setAmountType] = useState("");

  const notifySameCoin = () => toast(`Please choose two different coins.`);

  const [mode, setMode] = useState("crypto");

  const handleCompareButtonClick = () => {
    if (coinData.id === coin.id) {
      notifySameCoin();
      return;
    }
    sendCompareButtonClick(coinData.id, coin.id);
    navigate(
      `/compare/${coinData.id}/${coin.id}/${amount !== "" && amount !== 0
        ? `${amountType === "$" ? "?$=" : "?amount="}${amount}`
        : ""
      }`
    );
  };

  return (
    <StyledShortBoxContentWrapper
      withDesktopBackground
      withMobileBackground
      style={{
        padding: hasTags ? "0.4rem 0.7rem" : "2rem 0.7rem",
      }}
    >
      <Column width="90%" gap="0.75rem"> 
  <LightHeader >Compare With</LightHeader>
  <Dropdown
    defaultCoin={defaultCoins.bitcoin}
    setCoin={setCoin}
    isCompareWith={true}
    mode={mode}
    setMode={setMode}
    showStockSwitch={true}
    includeStocks={true}
  />
  <Row
    style={{
      width: "100%",
      gap: "0.5rem",
      flexWrap: "nowrap",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <AmountInput
      upperAmount={amount}
      mode={amountType}
      setMode={setAmountType}
      setUpperAmount={setAmount}
      coin={coinData}
      value={amount}
      style={{ flex: 1, minWidth: "8rem" }}
    />

    <button
      style={{
        backgroundColor: theme.colors.OLDLEVEL2,
        padding: "0.5rem 1rem",
        borderRadius: borderRads.SMALL,
        border: "none",
        flex: "0 1 auto",
        whiteSpace: "nowrap",
      }}
      onClick={!isFake ? handleCompareButtonClick : () => {}}
    >
      <SmallText> {isFake ? "Available soon" : "Compare"}</SmallText>
    </button>
  </Row>
</Column>


    </StyledShortBoxContentWrapper>
  );
}
