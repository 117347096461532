import React, { useEffect, useRef, useState } from "react";
import { AreaClosed, LinePath } from "@visx/shape";
import { curveMonotoneX } from "@visx/curve";
import { scaleTime, scaleLinear } from "@visx/scale";
import { GradientTealBlue } from "@visx/gradient";
import {
  getDsHistoricalPriceData,
  getExternalCoinGraphData,
  getHistoricalDataOfCoin,
  getNonDsHistoricalPriceData,
  getNonDs24HHistoricalPriceData,
} from "../stream";
import { theme } from "../theme.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import styled from "styled-components";
import { SmallText } from "../texts.styled";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import {
  formatNumber,
  getSmallNumber,
  graphFormatter,
} from "../../util/numberFormatter";
import Loader from "../misc/loader";
import { Icon } from "../icon.styled";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { TooltipWithBounds, defaultStyles, useTooltip } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { bisector } from "d3-array";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

const tooltipStyles = {
  ...defaultStyles,
  background: "rgba(255,255,255,1)",
  border: "1px solid white",
  color: "black",
  zIndex: 1,
};

export const AreaChartWrapper = ({
  coinId,
  coin,
  isDesktop,
  isCompareMobile,
  isCompareDesktop,
  handleOneDayChange,
}) => {
  const location = useLocation();

  const [data, setData] = useState(null);

  const [graphData, setGraphData] = useState(null);

  const [changes, setChanges] = useState(null);

  const [isLoading, setLoading] = useState(false);

  const [isError, setError] = useState(false);

  useEffect(() => {
    return () => {
      setLoading(false);
      setError(false);
      setData(null);
      setGraphData(null);
      setTotalDays(null);
    };
  }, []);

  const [totalDays, setTotalDays] = useState(null);

  async function fetchData() {
    try {
      setLoading(true);
    
      if (coin.source === "ds") {
        let hourlyData = await getDsHistoricalPriceData(
          coin.pairAddress,
          coin.blockChain
        );
        let dailyData = await getDsHistoricalPriceData(
          coin.pairAddress,
          coin.blockChain,
          "day"
        );
    
        let sortedDataHourly = hourlyData.reverse();
        let sortedDataDaily = dailyData.reverse();
    
        setData(
          sortedDataHourly.map((item) => ({
            date: new Date(item.x * 1000),
            value: item.y,
          }))
        );
    
        let oneDayData = sortedDataHourly
          .filter((item) => {
            let date = new Date(item.x * 1000);
            let today = new Date();
            let yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
    
            yesterday.setHours(today.getHours() - 1);
    
            return date >= yesterday;
          })
          .map((item) => ({
            date: new Date(item.x * 1000),
            value: item.y,
          }));
    
        let oneDayChange =
          ((oneDayData[oneDayData.length - 1].value - oneDayData[0].value) /
            oneDayData[0].value) *
          100;
    
        if (handleOneDayChange) handleOneDayChange(oneDayChange);
    
        let threeDaysData = sortedDataHourly
          .filter((item) => {
            let date = new Date(item.x * 1000);
            let today = new Date();
            let threeDaysAgo = new Date(today);
            threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    
            return date >= threeDaysAgo;
          })
          .map((item) => ({
            date: new Date(item.x * 1000),
            value: item.y,
          }));
    
        let threeDaysChange =
          ((threeDaysData[threeDaysData.length - 1].value -
            threeDaysData[0].value) /
            threeDaysData[0].value) *
          100;
    
        let oneWeekData = sortedDataHourly
          .filter((item) => {
            let date = new Date(item.x * 1000);
            let today = new Date();
            let lastWeek = new Date(today);
            lastWeek.setDate(lastWeek.getDate() - 7);
    
            return date >= lastWeek;
          })
          .map((item) => ({
            date: new Date(item.x * 1000),
            value: item.y,
          }));
    
        let oneWeekDifference =
          (oneWeekData[oneWeekData.length - 1].date - oneWeekData[0].date) /
          (1000 * 60 * 60 * 24);
    
        let oneWeekChange =
          ((oneWeekData[oneWeekData.length - 1].value - oneWeekData[0].value) /
            oneWeekData[0].value) *
          100;
    
        if (oneWeekDifference < 6) {
          oneWeekChange = null;
        }
    
        let oneMonthData = sortedDataDaily
          .filter((item) => {
            let date = new Date(item.x * 1000);
            let today = new Date();
            let lastMonth = new Date(today);
            lastMonth.setDate(lastMonth.getDate() - 30);
    
            return date >= lastMonth;
          })
          .map((item) => ({
            date: new Date(item.x * 1000),
            value: item.y,
          }));
    
        let oneMonthDifference =
          (oneMonthData[oneMonthData.length - 1].date - oneMonthData[0].date) /
          (1000 * 60 * 60 * 24);
    
        let oneMonthChange =
          ((oneMonthData[oneMonthData.length - 1].value -
            oneMonthData[0].value) /
            oneMonthData[0].value) *
          100;
    
        if (oneMonthDifference < 29) {
          oneMonthChange = null;
        }
    
        let sixMonthData = sortedDataDaily
          .filter((item) => {
            let date = new Date(item.x * 1000);
            let today = new Date();
            let lastMonth = new Date(today);
            lastMonth.setDate(lastMonth.getDate() - 180);
    
            return date >= lastMonth;
          })
          .map((item) => ({
            date: new Date(item.x * 1000),
            value: item.y,
          }));
    
        let firstDate = new Date(sixMonthData[0].date);
        let lastDate = new Date(sixMonthData[sixMonthData.length - 1].date);
    
        let diffTime = Math.abs(lastDate - firstDate);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
        setTotalDays(diffDays);
    
        setGraphData([oneDayData, oneWeekData, oneMonthData, sixMonthData]);
        setChanges([
          oneDayChange,
          threeDaysChange,
          oneWeekChange,
          oneMonthChange,
        ]);
      } else {
        let dailyData = await getNonDsHistoricalPriceData(coin.id, coin.source);
        let hourlyData = await getNonDs24HHistoricalPriceData(coin.id);
    
        if (dailyData.length <= 1) {
          setError(true);
          setLoading(false);
          return;
        }
    
        // Create a copy to avoid manipulating the original data
        let sortedDataDaily = [...dailyData];
    
        let currentPrice = coin.price;
    
        // Check if the last data point is from today and update it with current price
        if (
          new Date(
            sortedDataDaily[sortedDataDaily.length - 1].x
          ).toDateString() === new Date().toDateString()
        ) {
          sortedDataDaily[sortedDataDaily.length - 1] = {
            x: new Date().getTime(),
            y: currentPrice,
          };
        }
    
        setData(
          sortedDataDaily.map((item) => ({
            date: new Date(item.x),
            value: item.y,
          }))
        );
    
        let oneDayData = hourlyData.map((item) => ({
          date: new Date(item[0]),
          value: item[1],
        }));
    
        let oneDayChange =
          ((oneDayData[oneDayData.length - 1].value - oneDayData[0].value) /
            oneDayData[0].value) *
          100;
    
        if (handleOneDayChange) handleOneDayChange(oneDayChange);
    
        let threeDayData = sortedDataDaily
          .filter((item) => {
            let date = new Date(item.x);
            let today = new Date();
            let threeDaysAgo = new Date(today);
            threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    
            return date >= threeDaysAgo;
          })
          .map((item) => ({
            date: new Date(item.x),
            value: item.y,
          }));
    
        let threeDayChange =
          ((threeDayData[threeDayData.length - 1].value - threeDayData[0].value) /
            threeDayData[0].value) *
          100;
    
        let threeDayDifference =
          (threeDayData[threeDayData.length - 1].date - threeDayData[0].date) /
          (1000 * 60 * 60 * 24);
    
        if (threeDayDifference < 2) {
          threeDayChange = null;
        }
    
        let oneWeekData = sortedDataDaily
          .filter((item) => {
            let date = new Date(item.x);
            let today = new Date();
            let lastWeek = new Date(today);
            lastWeek.setDate(lastWeek.getDate() - 7);
    
            return date >= lastWeek;
          })
          .map((item) => ({
            date: new Date(item.x),
            value: item.y,
          }));
    
        let oneWeekChange =
          ((oneWeekData[oneWeekData.length - 1].value - oneWeekData[0].value) /
            oneWeekData[0].value) *
          100;
    
        let oneWeekDifference =
          (oneWeekData[oneWeekData.length - 1].date - oneWeekData[0].date) /
          (1000 * 60 * 60 * 24);
    
        if (oneWeekDifference < 6) {
          oneWeekChange = null;
        }
    
        let oneMonthData = sortedDataDaily
          .filter((item) => {
            let date = new Date(item.x);
            let today = new Date();
            let lastMonth = new Date(today);
            lastMonth.setDate(lastMonth.getDate() - 30);
    
            return date >= lastMonth;
          })
          .map((item) => ({
            date: new Date(item.x),
            value: item.y,
          }));
    
        let oneMonthChange =
          ((oneMonthData[oneMonthData.length - 1].value - oneMonthData[0].value) /
            oneMonthData[0].value) *
          100;
    
        let oneMonthDifference =
          (oneMonthData[oneMonthData.length - 1].date - oneMonthData[0].date) /
          (1000 * 60 * 60 * 24);
    
        if (oneMonthDifference < 29) {
          oneMonthChange = null;
        }
    
        let sixMonthData = sortedDataDaily
          .filter((item) => {
            let date = new Date(item.x);
            let today = new Date();
            let lastMonth = new Date(today);
            lastMonth.setDate(lastMonth.getDate() - 180);
    
            return date >= lastMonth;
          })
          .map((item) => ({
            date: new Date(item.x),
            value: item.y,
          }));
    
        let firstDate = new Date(sixMonthData[0].date);
        let lastDate = new Date(sixMonthData[sixMonthData.length - 1].date);
    
        let diffTime = Math.abs(lastDate - firstDate);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
        setTotalDays(diffDays);
    
        setGraphData([oneDayData, oneWeekData, oneMonthData, sixMonthData]);
        setChanges([oneDayChange, threeDayChange, oneWeekChange, oneMonthChange]);
      }
    
      setLoading(false);
    } catch (e) {
      if (handleOneDayChange) handleOneDayChange(coin.change24H);
      setError(true);
      setLoading(false);
      console.error(e);
    }
  }

  useEffect(() => {
    if (coin.source === "ds" && (!coin.pairAddress || !coin.blockChain)) {
      setError(true);
      setLoading(false);
      if (handleOneDayChange) handleOneDayChange(coin.change24H);
      return;
    }
    fetchData();
  
    return () => {
      setLoading(false);
      setError(false);
      setData(null);
      setGraphData(null);
      setChanges(null);
      setTotalDays(null);
    };
  }, [coin.id]); // Add coin.id as dependency

  console.log('graphData: ', graphData)
  return graphData && !isLoading ? (
    <AreaChart
      data={graphData}
      coin={coin}
      isDesktop={isDesktop}
      totalDays={totalDays}
      isCompareMobile={isCompareMobile}
      key={coinId}
      changes={changes}
      isCompareDesktop={isCompareDesktop}
    />
  ) : !isDesktop && !isCompareMobile && !isCompareDesktop ? null : isLoading ? (
    <div
      style={{
        color: "white",
        width: "100%",
        height: isCompareMobile ? "7rem" : isCompareDesktop ? "8.5rem" : "9rem",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        borderRadius: "0.5rem",
        border: "0.12rem solid " + theme.colors.OLDLEVEL2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader isSmall={true} />
    </div>
  ) : isError ? (
    <div
      style={{
        color: "white",
        width: "100%",
        height: isCompareMobile ? "7rem" : isCompareDesktop ? "8.5rem" : "9rem",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        borderRadius: "0.5rem",
        border: "0.12rem solid " + theme.colors.OLDLEVEL2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SmallText style={{ color: theme.colors.LIGHT_TEXT }}>
        No price data
        {isCompareDesktop || isCompareMobile
          ? ` for ${coin.symbol.toUpperCase()}`
          : "."}
      </SmallText>
    </div>
  ) : null;
};

export const AreaChart = ({
  data,
  coin,
  isDesktop,
  totalDays,
  changes,
  isCompareMobile,
}) => {
  // Accessors
  const x = (d) => d.date;
  const y = (d) => d.value;

  const [chartData, setChartData] = useState(data[0]);
  const [renderData, setRenderData] = useState(data[0]);

  const [width, setWidth] = useState(0);
  const height = isCompareMobile ? 80 : isDesktop ? 114 : 105;
  const margin = { top: 40, right: -1, bottom: 3, left: -1 };

  const containerRef = useRef(null);

  const [range, setRange] = useState(0);

  useEffect(() => {
    return () => {
      setChartData([]);
      setRenderData([]);
      setRange(0);
    };
  }, []);

  useEffect(() => {
    let newData = data[range];
    setPercentageChange(
      ((newData[newData.length - 1].value - newData[0].value) /
        newData[0].value) *
        100
    );
    if (coin.source === "ds") {
      // Keep DS logic exactly the same
      setRenderData(
        fillMissingDataPoints(
          newData,
          range === 0 ? 1 : range === 1 ? 7 : range === 2 ? 30 : 180,
          range === 0 || range === 1 ? "hourly" : "daily"
        )
      );
  
      setChartData(
        fillMissingDataPoints(
          newData,
          range === 0 ? 1 : range === 1 ? 7 : range === 2 ? 30 : 180,
          range === 0 || range === 1 ? "hourly" : "daily"
        ).reduce((acc, current) => {
          return [...acc, ...current.data];
        }, [])
      );
    } else {
      if (range === 0) {
        // For 1d non-DS data, use it directly
        setRenderData([{ isPadded: false, data: newData }]);
        setChartData(newData);
      } else {
        // For other ranges, keep original logic
        setRenderData(
          fillMissingDataPoints(
            newData,
            range === 1 ? 7 : range === 2 ? 30 : 180,
            "daily"
          )
        );
  
        setChartData(
          fillMissingDataPoints(
            newData,
            range === 1 ? 7 : range === 2 ? 30 : 180,
            "daily"
          ).reduce((acc, current) => {
            return [...acc, ...current.data];
          }, [])
        );
      }
    }
  }, [range]);

  const [percentageChange, setPercentageChange] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.clientWidth);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  // Tooltip setup code...
  const { tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
    useTooltip();

  const bisectDate = bisector((d) => d.date).left;

  const isMobile = useMediaQuery({
    query: "(max-width: 40rem)",
  });

  const handleTooltip = (event) => {
    if (isMobile) document.body.style.overflow = "hidden";

    const { x: xPoint } = localPoint(event) || { x: 0 };
    const x0 = xScale.invert(xPoint);
    const index = bisectDate(chartData, x0, 1);
    const d0 = chartData[index - 1];
    const d1 = chartData[index];
    let d = d0;
    if (d1 && d1.date) {
      d = x0 - d0.date > d1.date - x0 ? d1 : d0;
    }

    showTooltip({
      tooltipData: d,
      tooltipLeft: xScale(d.date),
      tooltipTop: yScale(d.value),
    });
  };

  // Scales
  const xScale = scaleTime({
    domain: [Math.min(...chartData.map(x)), Math.max(...chartData.map(x))],
    range: [margin.left, width - margin.right],
  });

  let maxValue = Math.max(...chartData.map(y));
  let minValue = Math.min(...chartData.map(y));

  let ratio = maxValue / minValue;

  const yScale = scaleLinear({
    domain: [minValue * (1 / ratio), maxValue],
    range: [height - margin.bottom, margin.top],
  });

  // Rest of the rendering code remains exactly the same...
  return (
    <div
      className="dedicated-coin-chart"
      ref={containerRef}
      style={{ width: "100%", position: "relative" }}
    >
      <RangeSelector
        totalDays={totalDays}
        selected={range}
        setSelected={setRange}
        isDs={coin.source === "ds"}
      />
      <Row
        gap={"0.2rem"}
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          justifyContent: "center",
          marginTop: "0.5rem",
        }}
      >
        <img
          style={{ width: "1.2rem", marginRight: "0.2rem" }}
          src={coin.image}
        />
        <h2>
        <SmallText>{coin.symbol.toUpperCase()} Price</SmallText>
        </h2>
      </Row>

      <SimpleChartChange isProfit={percentageChange >= 0}>
        <Row gap="0.25rem">
          {Math.abs(percentageChange) === 0 ? (
            "0"
          ) : Math.abs(percentageChange) > 0.001 ||
            getSmallNumber(percentageChange, true).zeroCount === 0 ? (
            graphFormatter(percentageChange, false, true).replace("-", "")
          ) : (
            <span>
              <span>0.0</span>
              <span
                style={{
                  fontSize: textSizes.fontSizes.MOBILE_LITTLE_NUMBER,
                  fontWeight: "500",
                }}
              >
                {getSmallNumber(percentageChange, true).zeroCount}
              </span>
              <span>
                {getSmallNumber(percentageChange, true).nonZeroDigits[0]}
              </span>
            </span>
          )}
          %
          <Icon>
            {percentageChange >= 0 ? (
              <Icon color={theme.colors.GREEN}>
                <BsCaretUpFill />
              </Icon>
            ) : (
              <Icon color={theme.colors.RED}>
                <BsCaretDownFill />
              </Icon>
            )}
          </Icon>
        </Row>
      </SimpleChartChange>
      <svg
        style={{
          borderRadius: `${borderRads.SMALL} ${borderRads.SMALL} 0 0`,
          border: "0.12rem solid " + theme.colors.OLDLEVEL2,
          //filter: "drop-shadow(0px 0px 7px rgba(130, 221, 85, 0.4))",
        }}
        width={width}
        height={height}
        onMouseMove={handleTooltip}
        onTouchMove={handleTooltip}
        onTouchEnd={() => {
          if (isMobile) document.body.style.overflow = "auto";
          hideTooltip();
        }}
        onTouchCancel={() => {
          if (isMobile) document.body.style.overflow = "auto";
          hideTooltip();
        }}
        onMouseLeave={() => {
          if (isMobile) document.body.style.overflow = "auto";
          hideTooltip();
        }}
      >
        <GradientTealBlue id="teal" />
        <rect
          width={width}
          height={height}
          fill={isDesktop ? "rgba(0, 0, 0, 0.1)" : theme.colors.SECONDARY}
        />

        {renderData.map((group, index) => {
          return (
            <>
              <LinePath
                key={index}
                data={group.data}
                x={(d) => xScale(d.date)}
                y={(d) => yScale(d.value)}
                yScale={yScale}
                xScale={xScale}
                stroke={
                  group.isPadded
                    ? "gray" // Use a different color for padded data
                    : percentageChange >= 0
                    ? theme.colors.GREEN
                    : theme.colors.RED
                }
                strokeWidth={1}
                strokeDasharray={group.isPadded ? "4" : "0"}
                curve={curveMonotoneX}
              />

              <AreaClosed
                data={group.data}
                x={(d) => xScale(x(d))}
                y={(d) => yScale(y(d))}
                yScale={yScale}
                xScale={xScale}
                fill={
                  group.isPadded
                    ? "rgba(255,255,255,0.07)" // Use a different color for padded data
                    : percentageChange >= 0
                    ? "rgb(130, 221, 85, 0.1)"
                    : "rgb(255, 63, 63, 0.1)"
                }
                curve={curveMonotoneX}
                strokeWidth={0}
                stroke={
                  percentageChange >= 0 ? theme.colors.GREEN : theme.colors.RED
                }
              />
            </>
          );
        })}

        {tooltipData && !tooltipData.leftPadding && (
          <g>
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={4}
              fill="white"
              stroke="black"
              strokeWidth={2}
              pointerEvents="none"
            />
          </g>
        )}
      </svg>
      {tooltipData && !tooltipData.leftPadding && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <Column align="start" gap="0.5rem">
            <SmallText weight={textSizes.fontWeights.SEMI_BOLD} color="black">
              {tooltipData.isPadded
                ? "No price data"
                : `$${formatNumber(y(tooltipData))}`}
            </SmallText>
            <SmallText color={"#848484"}>
              {tooltipData.isPadded
                ? "Volume was too low"
                : coin.source === "ds" && range === 0
                ? x(tooltipData).toLocaleString()
                : x(tooltipData).toLocaleDateString({
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
            </SmallText>
          </Column>
        </TooltipWithBounds>
      )}
      <ChartInfo>
        <Row>
          <SmallText
            weight={textSizes.fontWeights.SEMI_BOLD}
            color={theme.colors.LIGHT_TEXT}
          >
            1d:
          </SmallText>
          <SmallText>
            <Row gap="0.2rem">
              {changes[0] ? (
                <>
                  {graphFormatter(changes[0], false, true, false, true)}%
                  {changes[0] >= 0 ? (
                    <Icon color={theme.colors.GREEN}>
                      <BsCaretUpFill />
                    </Icon>
                  ) : (
                    <Icon color={theme.colors.RED}>
                      <BsCaretDownFill />
                    </Icon>
                  )}
                </>
              ) : (
                "-"
              )}
            </Row>
          </SmallText>
        </Row>
        <Row>
          <SmallText
            weight={textSizes.fontWeights.SEMI_BOLD}
            color={theme.colors.LIGHT_TEXT}
          >
            3d:
          </SmallText>
          <SmallText>
            <Row gap="0.2rem">
              {changes[1] ? (
                <>
                  {graphFormatter(changes[1], false, true, false, true)}%
                  {changes[1] >= 0 ? (
                    <Icon color={theme.colors.GREEN}>
                      <BsCaretUpFill />
                    </Icon>
                  ) : (
                    <Icon color={theme.colors.RED}>
                      <BsCaretDownFill />
                    </Icon>
                  )}
                </>
              ) : (
                "-"
              )}
            </Row>
          </SmallText>
        </Row>
        <Row>
          <SmallText
            weight={textSizes.fontWeights.SEMI_BOLD}
            color={theme.colors.LIGHT_TEXT}
          >
            1w:
          </SmallText>
          <SmallText>
            <Row gap="0.2rem">
              {changes[2] ? (
                <>
                  {graphFormatter(changes[2], false, true, false, true)}%
                  {changes[2] >= 0 ? (
                    <Icon color={theme.colors.GREEN}>
                      <BsCaretUpFill />
                    </Icon>
                  ) : (
                    <Icon color={theme.colors.RED}>
                      <BsCaretDownFill />
                    </Icon>
                  )}
                </>
              ) : (
                "-"
              )}
            </Row>
          </SmallText>
        </Row>
        <Row>
          <SmallText
            weight={textSizes.fontWeights.SEMI_BOLD}
            color={theme.colors.LIGHT_TEXT}
          >
            1m:
          </SmallText>
          <SmallText>
            <Row gap="0.2rem">
              {changes[3] ? (
                <>
                  {graphFormatter(changes[3], false, true, false, true)}%
                  {changes[3] >= 0 ? (
                    <Icon color={theme.colors.GREEN}>
                      <BsCaretUpFill />
                    </Icon>
                  ) : (
                    <Icon color={theme.colors.RED}>
                      <BsCaretDownFill />
                    </Icon>
                  )}
                </>
              ) : (
                "-"
              )}
            </Row>
          </SmallText>
        </Row>
      </ChartInfo>
    </div>
  );
};

export const ChartInfo = styled(FullRow)`
  margin-top: -0.3rem;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  gap: 0.5rem;
  justify-content: space-around;

  border-radius: 0 0 ${borderRads.SMALL} ${borderRads.SMALL};

  border: 0.07rem solid ${theme.colors.OLDLEVEL2};

  //if max width 70rem
  @media (max-width: 70rem) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  & > * {
    gap: 0.2rem;
    justify-content: start;

    & > * {
      font-size: 0.8rem;
      @media (max-width: 70rem) {
        height: 1rem;
      }
    }
  }
`;

export const RangeSelector = ({
  selected,
  setSelected,
  isHolder,
  days,
  isDs,
  totalDays,
}) => {
  let ranges = ["1d", "1w", "1m", "6m"];

  if (totalDays <= 7) {
    ranges = ranges.filter((range) => range !== "1m");
    ranges = ranges.filter((range) => range !== "6m");
  } else if (totalDays <= 30) {
    ranges = ranges.filter((range) => range !== "6m");
  }

  return (
    <StyledRangeSelector isHolder={isHolder}>
      {isHolder ? (
        <SmallText
          color={theme.colors.LIGHT_TEXT}
          size={textSizes.fontSizes.SMALLER}
        >
          Last {days}
        </SmallText>
      ) : (
        <>
          {ranges.map((range, index) => (
            <StyledRangeSelectorButton
              style={{ fontSize: "0.7rem" }}
              key={index}
              selected={selected === index}
              onClick={() => setSelected(index)}
            >
              {range}
            </StyledRangeSelectorButton>
          ))}
        </>
      )}
    </StyledRangeSelector>
  );
};

export const SimpleChartChange = styled.div`
  position: absolute;
  font-weight: ${textSizes.fontWeights.SEMI_BOLD};
  align-items: center;
  gap: 0.5rem;
  color: ${({ isProfit }) =>
    isProfit ? theme.colors.GREEN : theme.colors.RED};

  & > * {
    color: ${({ isProfit }) =>
      isProfit ? theme.colors.GREEN : theme.colors.RED};
  }

  & > * {
    span {
      color: ${({ isProfit }) =>
        isProfit ? theme.colors.GREEN : theme.colors.RED};
    }
  }

  top: ${({ isHolder }) => (isHolder ? "0.5rem" : "0.5rem")};
  right: 0.5rem;
`;

const StyledRangeSelector = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  gap: 0.2rem;
  color: ${theme.colors.LIGHT_TEXT};

  top: ${({ isHolder }) => (isHolder ? "0.5rem" : "0.5rem")};
  left: 0.5rem;
`;

const StyledRangeSelectorButton = styled.button`
  background: ${({ selected }) =>
    selected ? theme.colors.LEVEL2 : "transparent"};
  color: ${({ selected }) => (selected ? "white" : theme.colors.LIGHT_TEXT)};
  width: 1.7rem;
  height: 1.4rem;
  border-radius: ${borderRads.SMALLER};
  border: ${borderWidths.SMALL} solid ${theme.colors.LEVEL2};
  cursor: pointer;
`;

export default AreaChart;

function padArrayWithNewStructure(data, targetLength) {
  // Create a deep copy of the original array
  let newData = JSON.parse(JSON.stringify(data));

  // Convert the dates to Date objects
  newData.forEach((item) => {
    item.date = new Date(item.date);
  });

  // Calculate the interval between dates
  let interval = (newData[1].date - newData[0].date) / 1000; // interval in seconds

  for (let i = 1; i < newData.length - 1; i++) {
    let tempInterval = (newData[i + 1].date - newData[i].date) / 1000;
    if (tempInterval < interval) {
      interval = tempInterval;
    }
  }

  // Check if the current array already has the correct interval and target length
  let expectedStartDate = new Date(
    newData[newData.length - 1].date.getTime() -
      interval * 1000 * (targetLength - 1)
  );

  if (newData[0].date.getTime() === expectedStartDate.getTime()) {
    return newData;
  }

  // Generate the new dates for padding
  let startDate = new Date(
    newData[0].date.getTime() -
      interval * 1000 * (targetLength - newData.length)
  );

  let paddedSection = [];
  while (paddedSection.length < targetLength - newData.length) {
    paddedSection.push({ date: new Date(startDate), value: 0, isPadded: true });
    startDate = new Date(startDate.getTime() + interval * 1000);
  }

  // Combine the padded section with the original data
  let paddedData = [...paddedSection, ...newData];

  // Return the padded data
  return paddedData;
}

function fillMissingDataPoints(data, days, interval) {
  // Calculate targetLength based on days and interval
  let targetLength;
  if (interval === "hourly") {
    targetLength = days * 24; // 24 hours in a day
  } else if (interval === "daily") {
    targetLength = days; // 1 data point per day
  } else {
    throw new Error("Unsupported interval. Use 'hourly' or 'daily'.");
  }

  // Create a deep copy of the original array
  let newData = JSON.parse(JSON.stringify(data));

  // Convert the dates to Date objects
  newData.forEach((item) => {
    item.date = new Date(item.date);
  });

  // Determine the interval in milliseconds
  let intervalMs;
  if (interval === "hourly") {
    intervalMs = 60 * 60 * 1000; // 1 hour in milliseconds
  } else if (interval === "daily") {
    intervalMs = 24 * 60 * 60 * 1000; // 1 day in milliseconds
  } else {
    throw new Error("Unsupported interval. Use 'hourly' or 'daily'.");
  }

  // Define 10 minutes in milliseconds for alignment points
  const alignmentIntervalMs = 10 * 60 * 1000; // 10 minutes

  // Find the date of the last data point
  let lastDate = newData[newData.length - 1].date;

  // Calculate the date of the first data point
  let initialFirstDate = new Date(
    lastDate.getTime() - intervalMs * (targetLength - 1)
  );

  // Determine if padding is needed
  let firstDate = newData[0].date;
  if (firstDate.getTime() >= initialFirstDate.getTime() + intervalMs * 2) {
    // Data is more than one interval away from the calculated first date
    firstDate = initialFirstDate;
  } else {
    // Use the actual first date as the starting point
    firstDate = new Date(
      Math.min(firstDate.getTime(), initialFirstDate.getTime())
    );
  }

  // Generate the complete series of dates based on the target length
  let fullData = [];
  for (let i = 0; i < targetLength; i++) {
    let currentDate = new Date(firstDate.getTime() + intervalMs * i);

    // Check if interval is daily and if there's a data point on the same day
    if (interval === "daily") {
      let existingDataForDay = newData.find((d) => {
        return (
          d.date.getFullYear() === currentDate.getFullYear() &&
          d.date.getMonth() === currentDate.getMonth() &&
          d.date.getDate() === currentDate.getDate()
        );
      });

      if (existingDataForDay) {
        fullData.push(existingDataForDay); // Use existing data, skip padding
        continue;
      }
    }

    fullData.push({ date: currentDate, value: 0, isPadded: true });
  }

  // Merge the original data into the full series
  newData.forEach((item) => {
    let existingIndex = fullData.findIndex(
      (d) => d.date.getTime() === item.date.getTime()
    );
    if (existingIndex !== -1) {
      fullData[existingIndex] = { ...item, isPadded: false };
    } else {
      // Insert the original item if it does not exist in fullData
      fullData.push({ ...item, isPadded: false });
    }
  });

  // Sort fullData by date to ensure correct order
  fullData.sort((a, b) => a.date - b.date);

  // Group the date-value pairs and include isPadded flag
  let result = [];
  let currentGroup = { isPadded: fullData[0].isPadded, data: [] };

  for (let i = 0; i < fullData.length; i++) {
    if (fullData[i].isPadded === currentGroup.isPadded) {
      currentGroup.data.push({
        date: fullData[i].date,
        value: fullData[i].value,
        isPadded: fullData[i].isPadded,
      });
    } else {
      result.push(currentGroup);
      currentGroup = {
        isPadded: fullData[i].isPadded,
        data: [
          {
            date: fullData[i].date,
            value: fullData[i].value,
            isPadded: fullData[i].isPadded,
          },
        ],
      };
    }
  }

  // Push the last group to the result array
  if (currentGroup.data.length > 0) {
    result.push(currentGroup);
  }

  // If there are no padded groups, return the single non-padded group
  if (result.length === 1 && !result[0].isPadded) {
    return result;
  }

  // Add alignment points only to padded sections
  for (let i = 0; i < result.length; i++) {
    if (result[i].isPadded) {
      let prevGroup = result[i - 1];
      let nextGroup = result[i + 1];

      // Add alignment point before the padded section
      if (prevGroup && !prevGroup.isPadded) {
        let prevDate = prevGroup.data[prevGroup.data.length - 1].date;
        let alignmentBeforeDate = new Date(
          prevDate.getTime() + alignmentIntervalMs
        );
        result[i].data.unshift({
          date: alignmentBeforeDate,
          value: 0,
          isPadded: true,
          leftPadding: i === 0, // Set leftPadding only for the first padded group
        });
      }

      // Add alignment point after the padded section
      if (nextGroup && !nextGroup.isPadded) {
        let nextDate = nextGroup.data[0].date;
        let alignmentAfterDate = new Date(
          nextDate.getTime() - alignmentIntervalMs
        );
        result[i].data.push({
          date: alignmentAfterDate,
          value: 0,
          isPadded: true,
        });
      }

      // Set leftPadding for the first padded group
      if (i === 0) {
        result[i].data.forEach((item) => {
          item.leftPadding = true;
        });
      }
    }
  }

  // Perform linear interpolation between non-padded groups
  for (let i = 0; i < result.length; i++) {
    if (result[i].isPadded) {
      // Find preceding and succeeding non-padded groups
      let prevGroup = result[i - 1];
      let nextGroup = result[i + 1];

      if (
        prevGroup &&
        nextGroup &&
        !prevGroup.isPadded &&
        !nextGroup.isPadded
      ) {
        let prevDate = prevGroup.data[prevGroup.data.length - 1].date;
        let nextDate = nextGroup.data[0].date;
        let prevValue = prevGroup.data[prevGroup.data.length - 1].value;
        let nextValue = nextGroup.data[0].value;

        let numPoints = result[i].data.length;
        for (let j = 0; j < numPoints; j++) {
          let currentDate = result[i].data[j].date;
          let t = (currentDate - prevDate) / (nextDate - prevDate);
          result[i].data[j].value = prevValue + t * (nextValue - prevValue);
        }
      }
    }
  }

  return result;
}

// Test with the provided input
const data = [
  {
    date: "2024-08-17T00:00:00.000Z",
    value: 0.056169656097726395,
  },
  {
    date: "2024-08-17T11:24:33.800Z",
    value: 0.0587490014731884,
  },
];

const filledData = fillMissingDataPoints(data, 7, "daily");

function calculatePercentageChangeWithTargetLength(
  data,
  targetLength,
  interval
) {
  // Create a deep copy of the original array
  let newData = JSON.parse(JSON.stringify(data));

  // Convert the dates to Date objects
  newData.forEach((item) => {
    item.date = new Date(item.date);
  });

  // Determine the interval in milliseconds
  let intervalMs;
  if (interval === "hourly") {
    intervalMs = 60 * 60 * 1000; // 1 hour in milliseconds
  } else if (interval === "daily") {
    intervalMs = 24 * 60 * 60 * 1000; // 1 day in milliseconds
  } else {
    throw new Error("Unsupported interval. Use 'hourly' or 'daily'.");
  }

  // Define 10 minutes in milliseconds for alignment points
  const alignmentIntervalMs = 10 * 60 * 1000; // 10 minutes

  // Find the date of the last data point
  let lastDate = newData[newData.length - 1].date;

  // Calculate the date of the first data point
  let initialFirstDate = new Date(
    lastDate.getTime() - intervalMs * (targetLength - 1)
  );

  // Determine if padding is needed
  let firstDate = newData[0].date;
  if (firstDate.getTime() > initialFirstDate.getTime() + intervalMs) {
    // Data is more than one interval away from the calculated first date
    firstDate = initialFirstDate;
  } else {
    // Use the actual first date as the starting point
    firstDate = new Date(
      Math.min(firstDate.getTime(), initialFirstDate.getTime())
    );
  }

  // Generate the complete series of dates based on the target length
  let fullData = [];
  for (let i = 0; i < targetLength; i++) {
    let currentDate = new Date(firstDate.getTime() + intervalMs * i);
    fullData.push({ date: currentDate, value: 0, isPadded: true });
  }

  // Merge the original data into the full series
  newData.forEach((item) => {
    let existingIndex = fullData.findIndex(
      (d) => d.date.getTime() === item.date.getTime()
    );
    if (existingIndex !== -1) {
      fullData[existingIndex] = { ...item, isPadded: false };
    }
  });

  // Group the date-value pairs and include isPadded flag
  let result = [];
  let currentGroup = { isPadded: fullData[0].isPadded, data: [] };

  for (let i = 0; i < fullData.length; i++) {
    if (fullData[i].isPadded === currentGroup.isPadded) {
      currentGroup.data.push({
        date: fullData[i].date,
        value: fullData[i].value,
        isPadded: fullData[i].isPadded,
      });
    } else {
      result.push(currentGroup);
      currentGroup = {
        isPadded: fullData[i].isPadded,
        data: [
          {
            date: fullData[i].date,
            value: fullData[i].value,
            isPadded: fullData[i].isPadded,
          },
        ],
      };
    }
  }

  // Push the last group to the result array
  if (currentGroup.data.length > 0) {
    result.push(currentGroup);
  }

  if (result.length === 1 && !result[0].isPadded) {
    let allDataPoints = result.reduce((acc, current) => {
      return [...acc, ...current.data];
    }, []);

    return result;
  }

  // Add alignment points only to padded sections
  for (let i = 0; i < result.length; i++) {
    if (result[i].isPadded) {
      let prevGroup = result[i - 1];
      let nextGroup = result[i + 1];

      // Add alignment point before the padded section
      if (prevGroup && !prevGroup.isPadded) {
        let prevDate = prevGroup.data[prevGroup.data.length - 1].date;
        let alignmentBeforeDate = new Date(
          prevDate.getTime() + alignmentIntervalMs
        );
        result[i].data.unshift({
          date: alignmentBeforeDate,
          value: 0,
          isPadded: true,
          leftPadding: i === 0, // Set leftPadding only for the first padded group
        });
      }

      // Add alignment point after the padded section
      if (nextGroup && !nextGroup.isPadded) {
        let nextDate = nextGroup.data[0].date;
        let alignmentAfterDate = new Date(
          nextDate.getTime() - alignmentIntervalMs
        );
        result[i].data.push({
          date: alignmentAfterDate,
          value: 0,
          isPadded: true,
        });
      }

      // Set leftPadding for the first padded group
      if (i === 0) {
        result[i].data.forEach((item) => {
          item.leftPadding = true;
        });
      }
    }
  }

  // Perform linear interpolation between non-padded groups
  for (let i = 0; i < result.length; i++) {
    if (result[i].isPadded) {
      // Find preceding and succeeding non-padded groups
      let prevGroup = result[i - 1];
      let nextGroup = result[i + 1];

      if (
        prevGroup &&
        nextGroup &&
        !prevGroup.isPadded &&
        !nextGroup.isPadded
      ) {
        let prevDate = prevGroup.data[prevGroup.data.length - 1].date;
        let nextDate = nextGroup.data[0].date;
        let prevValue = prevGroup.data[prevGroup.data.length - 1].value;
        let nextValue = nextGroup.data[0].value;

        let numPoints = result[i].data.length;
        for (let j = 0; j < numPoints; j++) {
          let currentDate = result[i].data[j].date;
          let t = (currentDate - prevDate) / (nextDate - prevDate);
          result[i].data[j].value = prevValue + t * (nextValue - prevValue);
        }
      }
    }
  }

  //get all data points that are not padded, and put them in an array
  let allDataPoints = result.reduce((acc, current) => {
    return [...acc, ...current.data];
  }, []);

  return result;
}
