import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { FiTrendingUp } from "react-icons/fi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import { TrendsContext } from "../../context";
import { RouteLink } from "../../routing/routeLink";
import {
  add3Dots,
  formatNumber,
  getSmallNumber,
  graphFormatter,
  maxLittleNumber,
} from "../../util/numberFormatter";
import { FullRow, Row } from "../containers/flexbox.styled";
import { HideOnMobileWrapper, ShowOnMobileWrapper } from "../hideOnMobile";
import { Icon } from "../icon.styled";
import Loader from "../misc/loader";
import { textSizes } from "../sizes";
import { getTrending } from "../stream";
import { Header, SmallText } from "../texts.styled";
import { StyledTable, StyledTableWrapper } from "./table.styled";
import TableSwitch from "./tableSwitch";
import { isSocialLinkValid } from "../../validators";
import { TransparentButton } from "../buttons/buttons.styled";
import { BiWorld } from "react-icons/bi";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function TopGainingTable({ isWithSwitch, isExternal, isHome }) {
  const navigate = useNavigate();

  const { mcData, trendingData } = useContext(TrendsContext);

  const {
    data: trendingData2,
    status,
    refetch: fetchTrending,
  } = useQuery(["trending-page"], () => getTrending(), {
    enabled: false,
  });

  useState(() => {
    if (isWithSwitch) {
      fetchTrending();
    }
  }, []);

  return (isWithSwitch && status === "success" && trendingData) ||
    !isWithSwitch ? (
    <StyledTableWrapper isExternal={isExternal}>
      <FullRow verticalOnMobile={true} justify={"space-between"}>
        <Row
          notFullOnMobile={true}
          gap={isExternal ? "0.5rem" : "1rem"}
          style={isExternal ? { width: "100%", justifyContent: "center" } : {}}
        >
          <Icon
            size={isExternal ? "1.4rem" : "2rem"}
            color={({ theme }) => theme.colors.GREEN}
          >
            <FiTrendingUp />
          </Icon>
          <RouteLink
            to="/top-gainers"
            style={isExternal ? {} : { pointerEvents: "none" }}
          >
            <Row
              gap="0.1rem"
              style={
                isExternal
                  ? {
                      cursor: "pointer",
                      justifyContent: "center",
                      width: "fit-content",
                    }
                  : {}
              }
            >
              <Header size={isExternal && "1rem"}>Top Gaining Coins</Header>
              {isExternal && (
                <Icon size={"1.5rem"}>
                  <MdKeyboardArrowRight />
                </Icon>
              )}
            </Row>
          </RouteLink>
        </Row>
        {isWithSwitch && <TableSwitch index={1} />}
      </FullRow>
      <div
        style={{
          overflowX: "auto",
          width: "100%",
        }}
      >
        <ShowOnMobileWrapper></ShowOnMobileWrapper>
        <StyledTable isHome={isHome}>
          <tr>
            <th>#</th>
            <th>Coin</th>
            {/* {!isExternal && (
              <HideOnMobileWrapper>
                <th style={{ width: "100%", paddingLeft: "1rem" }}>Links</th>
              </HideOnMobileWrapper>
            )} */}

            <th>Price</th>
            {!isExternal && <th>1h</th>}
            <th>1d</th>
            {!isExternal && <th>7d</th>}
            {!isExternal && <th>Volume(24h)</th>}
            <th>{isHome ? "MC" : "Market Cap"}</th>
          </tr>
          {(isWithSwitch && status === "success" ? trendingData2 : trendingData)
            .slice(0, isWithSwitch ? 100 : 10)
            .map((item, index) => (
              <tr key={index}>
                <td>
                  <Link 
                    to={`/coin/${item.id}`} 
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                   <SmallText>{index + 1}</SmallText>
                  </Link>
                </td>
                <td>
                  <Link 
                    to={`/coin/${item.id}`} 
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                  <Row gap={"0.7rem"}>
                    <img alt="logo" src={item.image} />
                    {!isExternal && (
                      <HideOnMobileWrapper>
                        {add3Dots(item.coinName, isExternal ? 10 : 20)}
                      </HideOnMobileWrapper>
                    )}
                    <>
                      <HideOnMobileWrapper>
                        <SmallText
                          weight={textSizes.fontWeights.SEMI_BOLD}
                          color={({ theme }) => theme.colors.LIGHT_TEXT}
                          //make it ellipsis
                        >
                          {item.symbol.toUpperCase()}
                        </SmallText>
                      </HideOnMobileWrapper>
                      <ShowOnMobileWrapper>
                        <SmallText
                          weight={textSizes.fontWeights.SEMI_BOLD}
                          color={({ theme }) => theme.colors.LIGHT_TEXT}
                        >
                          {isExternal
                            ? add3Dots(item.symbol, 6).toUpperCase()
                            : item.symbol.toUpperCase()}
                        </SmallText>
                      </ShowOnMobileWrapper>
                    </>
                  </Row>
                  </Link>
                </td>
                {/* {!isExternal && (
                  <HideOnMobileWrapper>
                    <td>
                      {isExternal && (
                        <Row gap={"0.5rem"} style={{ marginLeft: "1rem" }}>
                          {isSocialLinkValid(item.web) && (
                            <a
                              aria-label="Go to website."
                              href={
                                item.web.slice(0, 8) === "https://"
                                  ? item.web
                                  : `https://${item.web}`
                              }
                              target={"_blank"}
                            >
                              <TransparentButton>
                                <Icon
                                  className="white-on-hover"
                                  size={"1.2rem"}
                                  color={({ theme }) => theme.colors.LIGHT_TEXT}
                                >
                                  <BiWorld />
                                </Icon>
                              </TransparentButton>
                            </a>
                          )}

                          {item.twitter && (
                            <a
                              aria-label="Go to X profile."
                              href={item.twitter}
                              target={"_blank"}
                            >
                              <TransparentButton>
                                <Icon
                                  className="white-on-hover"
                                  size={"1.2rem"}
                                  color={({ theme }) => theme.colors.LIGHT_TEXT}
                                >
                                  <FaXTwitter />
                                </Icon>
                              </TransparentButton>
                            </a>
                          )}

                          {isSocialLinkValid(item.telegram) && (
                            <a
                              aria-label="Go to Telegram."
                              href={`https://t.me/${item.telegram}`}
                              target={"_blank"}
                            >
                              <TransparentButton>
                                <Icon
                                  size={"1.2rem"}
                                  color={({ theme }) => theme.colors.LIGHT_TEXT}
                                  className="white-on-hover"
                                >
                                  <FaTelegramPlane />
                                </Icon>
                              </TransparentButton>
                            </a>
                          )}
                        </Row>
                      )}
                    </td>
                  </HideOnMobileWrapper>
                )} */}

                {item.price < 0.001 ? (
                  <td>
                  <Link 
                    to={`/coin/${item.id}`} 
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <SmallText>
                      $0.0
                      <SmallText
                        size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
                      >
                        {getSmallNumber(item.price).zeroCount}
                      </SmallText>
                      {getSmallNumber(item.price).nonZeroDigits}
                    </SmallText>
                  </Link>
                  </td>
                ) : (
                  <td>
                  <Link 
                    to={`/coin/${item.id}`} 
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <SmallText>${formatNumber(item.price)}</SmallText>
                  </Link>
                  </td>
                )}
                {!isExternal && (
                  <td>
                  <Link 
                    to={`/coin/${item.id}`} 
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <SmallText
                      style={
                        item.change1H
                          ? item.change1H > 1
                            ? { color: "#82dd55" }
                            : { color: "#ff3f3f" }
                          : { color: "#ffffff" }
                      }
                    >
                      {item.change1H
                        ? `${graphFormatter(item.change1H, false, true)}%`
                        : "-"}
                    </SmallText>
                  </Link>
                  </td>
                )}

                  <td>
                  <Link 
                    to={`/coin/${item.id}`} 
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                  <SmallText
                    style={
                      item.change24H
                        ? item.change24H > 1
                          ? { color: "#82dd55" }
                          : { color: "#ff3f3f" }
                        : { color: "#ffffff" }
                    }
                  >
                    {item.change24H
                      ? `${graphFormatter(item.change24H, false, true)}%`
                      : "-"}
                  </SmallText>
                  </Link>
                </td>

                {!isExternal && (
                  <td>
                  <Link 
                    to={`/coin/${item.id}`} 
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <SmallText
                      style={
                        item.change7D
                          ? item.change7D > 1
                            ? { color: "#82dd55" }
                            : { color: "#ff3f3f" }
                          : { color: "#ffffff" }
                      }
                    >
                      {item.change7D
                        ? `${graphFormatter(item.change7D, false, true)}%`
                        : "-"}
                    </SmallText>
                    </Link>
                  </td>
                )}

                {!isExternal && (
                  <td>
                  <Link 
                    to={`/coin/${item.id}`} 
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <SmallText>${graphFormatter(item.volume24H)}</SmallText>
                  </Link>
                  </td>
                )}

                <td>
                  <Link 
                    to={`/coin/${item.id}`} 
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                  <SmallText>${graphFormatter(item.marketcap)}</SmallText>
                  </Link>
                </td>
              </tr>
            ))}
        </StyledTable>
      </div>
      {/* {!isWithSwitch && (
        <RouteLink aria-label="Go to top gainers." to={"/top-gainers"}>
          <Row gap={"0.3rem"}>
            <SmallText>See more trending coins.</SmallText>
            <Icon size={"1.5rem"}>
              <MdKeyboardArrowRight />
            </Icon>
          </Row>
        </RouteLink>
      )} */}
    </StyledTableWrapper>
  ) : (
    <Loader height={"30rem"} />
  );
}
