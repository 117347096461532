import React, { useState } from "react";
import { StyledSwitch } from "./switch.styled";
import { SwitchOption } from "./switchOption.styled";
import { LuBadge } from "react-icons/lu";

export default function Switch({
  options,
  center,
  fullWidth,
  selectedIndexs,
  backgroundColor,
  selectedBackgroundColor,
  width,
  isCompare,
  hasNew,
  isLonger,
}) {

    const selectedIndex = selectedIndexs !== undefined ? selectedIndexs : 0;


    function handleSelection(item, index) {
      item.onClick();
    }
  
  return (
    <StyledSwitch
      isCompare={isCompare}
      width={width}
      backgroundColor={backgroundColor}
      fullWidth={fullWidth}
      center={center}
      style={hasNew ? { overflow: "visible" } : {}}
    >
      {options.map((item, index) => {
        return (
          <SwitchOption
            selectedBackgroundColor={selectedBackgroundColor}
            key={index}
            selected={index === selectedIndex}
            onClick={() => handleSelection(item, index)}
            isNew={item.isNew}
            style={item.isNew ? {} : {}}
            style={isLonger ? { minWidth: "9rem"} : {}}
          >
            {item.image ? <img alt="logo" src={item.image} /> : item.name}
            {item.isNew && (
              <img src="/images/new-badge.svg" alt="new" className="new" />
            )}
          </SwitchOption>
        );
      })}
    </StyledSwitch>
  );
}
