import styled from "styled-components";
import {Column} from "../containers/flexbox.styled";
import {paddings} from "../paddings.styled";
import {borderRads, borderWidths} from "../sizes";

export const StyledComparerFive = styled(Column)`
  background-color: ${({theme}) => theme.colors.LEVEL};
  padding: ${paddings.HIGH};
  gap: 1rem;
  align-items: center;
  border-radius: ${borderRads.SMALL};
  width: 100%;
  
  @media(max-width: 70rem){
    width: 100%;
    padding: ${paddings.LOW};
    background-color: ${({theme}) => theme.colors.SECONDARY};
    border: none;
  }
`

export const ComparerFiveImage = styled.div`
  position: absolute;
  top: 50%; /* Moves the image lower */
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a1a1a; /* Optional: Background matching */
  border-radius: 50%;
  padding: 1px;
  z-index: 1; /* Ensures it appears above the switch */
`;