import styled from "styled-components";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { Header, Paragraph, SmallText, Title } from "../texts.styled";
import { useContext, useEffect, useState, useMemo } from "react";
import { CoinsContext } from "../../context";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { paddings } from "../paddings.styled";
import { VerticalSeperator } from "../containers/boxes.styled";
import { theme } from "../theme.styled";
import {
  formatNumber,
  getSmallNumber,
  graphFormatter,
} from "../../util/numberFormatter";
import React from "react";
import { getAthMarketCap } from "../stream";
import { useQuery } from "@tanstack/react-query";
import { defaultAthMarketCaps } from "../compareSection/defaultCoins";
import Switch from "../misc/switch";
import CompareShareButton from "./compareShareButton";
import { RouteLink } from "../../routing/routeLink";
import { useNavigate, useParams } from "react-router-dom";


export default function CompareResult({ setAthTimes, setMode }) {
  const { coin1data, coin2data } = useContext(CoinsContext);
  const { coin1Id, coin2Id, type } = useParams();


  const [isCoin1Stock, isCoin2Stock] = useMemo(() => [
    coin1data?.id?.endsWith('-stock'),
    coin2data?.id?.endsWith('-stock')
  ], [coin1data, coin2data]);

  const isStock = useMemo(() =>
    coin2data?.id?.endsWith('-stock'),
    [coin2data]
  );

  console.log('coin2mcap: ', coin2data.marketcap);
  console.log('coin1mcap: ', coin1data.marketcap);
  console.log('coin1price: ', coin1data.price);
  console.log('coin2price: ', coin2data.price);


  const futurePrice =
    (coin2data.marketcap / coin1data.marketcap) * coin1data.price;
  const futureTimes = coin2data.marketcap / coin1data.marketcap;
  console.log('futurePrice: ', futurePrice);
  const compareTypes = {
    current: "current",
    ath: "ath",
  };

  const [compareType, setCompareType] = useState(type === compareTypes.ath ? compareTypes.ath : compareTypes.current);

  const [futurePriceAth, setFuturePriceAth] = useState(0);
  const [futureTimesAth, setFutureTimesAth] = useState(0);

  const [athMode, setAthMode] = useState("current");
  const [showAth, setShowAth] = useState(false);

  const handleCompareTypeChange = (newType) => {
    setCompareType(newType);
    navigate(`/compare/${coin1data.id}/${coin2data.id}/${newType}`);
  };

  useEffect(() => {
    if (isStock) {
      setShowAth(false);
      setAthTimes(null); // Reset ATH times
      return; // Early return if it's a stock
    }

    fetchAthMarketCap();
  }, [coin2data]);

  useEffect(() => {
    const pathParts = window.location.pathname.split("/");
    const lastSegment = pathParts[pathParts.length - 1];
  
    if (lastSegment === compareTypes.ath) {
      setCompareType(compareTypes.ath);
    } else {
      setCompareType(compareTypes.current);
    }
  }, []);
  

  const navigate = useNavigate();

  //each time the comparetype changes, setmode
  React.useEffect(() => {
    setMode(compareType);
  }, [compareType]);

  const {
    data: athMarketCap,
    status,
    refetch: fetchAthMarketCap,
  } = useQuery(
    ["mCapAth", coin2data.id],
    () => getAthMarketCap(coin2data.id, coin2data.athDate, coin2data),
    {
      enabled: !isStock,
      onSuccess: (data) => {
        console.log(data);
        // Case 1: Data service issue
        if (data === "coin-ds") {
          if (!coin2data.ath) {
            return;
          }
          let result = coin2data.ath * coin2data.circSupply;
          setFuturePriceAth((result / coin1data.marketcap) * coin1data.price);
          setFutureTimesAth(result / coin1data.marketcap);
          setShowAth(true);
          setAthTimes(result / coin1data.marketcap);
          return;
        }
        
        // Case 2: Check default values
        const defaultAthCoin = defaultAthMarketCaps.find(
          (item) => item.id === coin2data.id
        );
      
        if (defaultAthCoin !== undefined) {
          data = defaultAthCoin.marketcap;
        }
        
        // Case 3: Special case for PEPE
        if (coin2data.id === "pepe") {
          data = coin2data.ath * coin2data.circSupply;
        }
      
        // Case 4: NEW FALLBACK - Handle when data is still missing
        if (!data) {
          // Only proceed if we have ATH price data
          if (!coin2data.ath) {
            return;
          }
          
          // Calculate ATH market cap using ATH price * circulating supply
          const calculatedAthMarketCap = coin2data.ath * coin2data.circSupply;
          
          setFuturePriceAth((calculatedAthMarketCap / coin1data.marketcap) * coin1data.price);
          setFutureTimesAth(calculatedAthMarketCap / coin1data.marketcap);
          setShowAth(true);
          setAthTimes(calculatedAthMarketCap / coin1data.marketcap);
          return;
        }
      
        // Case 5: Normal path - data is available
        setFuturePriceAth((data / coin1data.marketcap) * coin1data.price);
        setFutureTimesAth(data / coin1data.marketcap);
        setShowAth(true);
        setAthTimes(data / coin1data.marketcap);
      },
      onError: (data) => { },
      staleTime: 0,
    }
  );


  return (
    <StyledCompareResult id="compareresult">
      <FullRow justify={"center"}>
        {isCoin1Stock ? (
          <Row gap={"0.5rem"} justify="end" width="8rem !important">
            <Header>{coin1data.symbol}</Header>
            <img
              style={{ width: "2rem", height: "2rem" }}
              alt="logo"
              src={coin1data.image}
            />
          </Row>
        ) : (
          <RouteLink to={`/coin/${coin1data.id}`}>
            <Row gap={"0.5rem"} justify="end" width="8rem !important">
              <Header>{coin1data.symbol.toUpperCase()}</Header>
              <img
                style={{ width: "2rem", height: "2rem" }}
                alt="logo"
                src={coin1data.image}
              />
            </Row>
          </RouteLink>
        )}

        <Paragraph
          style={{ width: "4rem", textAlign: "center" }}
          weight={textSizes.fontWeights.SEMI_BOLD}
        >
          vs
        </Paragraph>

        {isCoin2Stock ? (
          <Row gap="0.5rem" width="8rem !important">
            <img
              style={{ width: "2rem", height: "2rem" }}
              alt="logo"
              src={coin2data.image}
            />
            <Column>
              <Header>{coin2data.symbol}</Header>
            </Column>
          </Row>
        ) : (
          <RouteLink to={`/coin/${coin2data.id}`}>
            <Row gap="0.5rem" width="8rem !important">
              <img
                style={{ width: "2rem", height: "2rem" }}
                alt="logo"
                src={coin2data.image}
              />
              <Header>{coin2data.symbol.toUpperCase()}</Header>
            </Row>
          </RouteLink>
        )}
      </FullRow>

      <div style={{ position: "absolute", right: "1rem", top: "0.8rem" }}>
        <CompareShareButton coin1data={coin1data} coin2data={coin2data} />
      </div>
      <Column height="2.1rem">
        {!isStock ? (
          <Switch
            height="2.1rem"
            center={true}
            selectedIndexs={compareType === compareTypes.current ? 0 : 1}  // Add this line
            options={
              showAth
                ? [
                  {
                    name: "Current",
                    onClick: () => handleCompareTypeChange(compareTypes.current),
                  },
                  {
                    name: "ATH",
                    onClick: () => handleCompareTypeChange(compareTypes.ath),
                  },
                ]
                : [
                  {
                    name: "Current",
                    onClick: () => setCompareType(compareTypes.current),
                  },
                ]
            }
          />
        ) : (
          <Switch
            height="2.1rem"
            center={true}
            selectedIndexs={0}
            options={[
              {
                name: "Current",
                onClick: () => { },
              }
            ]}
          />
        )}
      </Column>
      <SmallText
        className="main-text"
        align="center"
        weight={textSizes.fontWeights.LIGHT}
      >
        Price of{" "}
        <span
          onClick={() => !isCoin1Stock && navigate(`/coin/${coin1data.id}`)}
          style={{
            fontWeight: textSizes.fontWeights.SEMI_BOLD,
            cursor: isCoin1Stock ? 'default' : 'pointer'
          }}
        >
          {coin1data.companyName || coin1data.coinName}
        </span>{" "}
        when its market cap <br /> reaches the{" "}
        {!isStock && compareType === compareTypes.ath ? "ATH" : "current"} market cap of{" "}
        <span style={{ fontWeight: textSizes.fontWeights.SEMI_BOLD }}>
          {coin2data.companyName || coin2data.coinName}
        </span>
      </SmallText>
      {/* <Row width="fit-content" gap="0.5rem" justify="center">
        <SmallText
          onClick={() => setCompareType(compareTypes.current)}
          weight={
            compareType === compareTypes.current
              ? textSizes.fontWeights.MEDIUM
              : textSizes.fontWeights.LIGHT
          }
          style={{ textAlign: "end", cursor: "pointer" }}
          width="4rem"
          color={
            compareType === compareTypes.current
              ? "white"
              : theme.colors.LIGHT_TEXT
          }
        >
          Current
        </SmallText>
        {showAth && (
          <>
            <VerticalSeperator width="0.05rem" height="1.2rem" />
            <SmallText
              width="4rem"
              onClick={() => setCompareType(compareTypes.ath)}
              style={{ cursor: "pointer" }}
              color={
                compareType === compareTypes.ath
                  ? "white"
                  : theme.colors.LIGHT_TEXT
              }
              weight={
                compareType === compareTypes.ath
                  ? textSizes.fontWeights.MEDIUM
                  : textSizes.fontWeights.LIGHT
              }
            >
              ATH
            </SmallText>
          </>
        )}
      </Row> */}
      <StyledFutureResultNew>
        <span
          style={{
            color: theme.colors.LIGHT_TEXT,
            opacity: 0.5,
            fontSize: "0.8rem",
            position: "absolute",
            top: "-0.95rem",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          comparemarketcap.com
        </span>
        $
        {(compareType === compareTypes.ath ? futurePriceAth : futurePrice) !== undefined &&
          (compareType === compareTypes.ath ? futurePriceAth : futurePrice) !== null ? (
          (compareType === compareTypes.ath ? futurePriceAth : futurePrice) > 0.001 ? (
            formatNumber(compareType === compareTypes.ath ? futurePriceAth : futurePrice, true)
          ) : (
            <React.Fragment>
              <span>0.0</span>
              <SmallText>
                {getSmallNumber(compareType === compareTypes.ath ? futurePriceAth : futurePrice || 0).zeroCount}
              </SmallText>
              <span>
                {getSmallNumber(compareType === compareTypes.ath ? futurePriceAth : futurePrice || 0).nonZeroDigits}
              </span>
            </React.Fragment>
          )
        ) : (
          "0.00"
        )}{" "}
        <span
          style={{
            color:
              (compareType === compareTypes.ath ? futureTimesAth : futureTimes) >= 1
                ? theme.colors.GREEN
                : theme.colors.RED,
          }}
        >
          ({formatNumber(compareType === compareTypes.ath ? futureTimesAth : futureTimes || 0, false)}x)
        </span>
      </StyledFutureResultNew>
      <StyledCompareResultInfoRow>
        <FullRow>
          {isCoin1Stock ? (
            <img src={coin1data.image} alt="logo" style={{ width: "1.5rem" }} />
          ) : (
            <RouteLink to={`/coin/${coin1data.id}`}>
              <img src={coin1data.image} alt="logo" style={{ width: "1.5rem" }} />
            </RouteLink>
          )}
          <VerticalSeperator width="0.05rem" height="2.5rem" />
          <Column align="start" gap="0.5rem">
            <SmallText size={"0.75rem"} color={theme.colors.LIGHT_TEXT}>
              Price
            </SmallText>
            <SmallText>
              $
              {coin1data.price > 0.001 ? (
                formatNumber(coin1data.price, true)
              ) : (
                <span>
                  0.0
                  <SmallText size={"0.7rem !important"}>
                    {getSmallNumber(coin1data.price).zeroCount}
                  </SmallText>
                  {getSmallNumber(coin1data.price).nonZeroDigits}
                </span>
              )}
            </SmallText>
          </Column>
          <Column align="start" gap="0.5rem" style={{ marginLeft: "1rem" }}>
            <SmallText
              style={{
                whiteSpace: "nowrap",
              }}
              size={"0.75rem"}
              color={theme.colors.LIGHT_TEXT}
            >
              Market Cap
            </SmallText>
            <SmallText>${graphFormatter(coin1data.marketcap)}</SmallText>
          </Column>
        </FullRow>
        <FullRow>
          <img src={coin2data.image} alt="logo" style={{ width: "1.5rem" }} />
          <VerticalSeperator width="0.05rem" height="2.5rem" />
          <Column align="start" gap="0.5rem">
            <SmallText size={"0.75rem"} color={theme.colors.LIGHT_TEXT}>
              {isStock ? "Stock Price" : compareType === compareTypes.current ? "Price" : "ATH Price"}
            </SmallText>
            <SmallText>
              ${coin2data.price > 0.001 ? (
                formatNumber(isStock ? coin2data.price : compareType === compareTypes.ath ? coin2data.ath : coin2data.price, true)
              ) : (
                <span>
                  0.0
                  <SmallText size={"0.7rem !important"}>
                    {getSmallNumber(isStock ? coin2data.price : compareType === compareTypes.ath ? coin2data.ath : coin2data.price).zeroCount}
                  </SmallText>
                  {getSmallNumber(isStock ? coin2data.price : compareType === compareTypes.ath ? coin2data.ath : coin2data.price).nonZeroDigits}
                </span>
              )}
            </SmallText>
          </Column>
          <Column align="start" gap="0.5rem" style={{ marginLeft: "1rem" }}>
            <SmallText size={"0.75rem"} color={theme.colors.LIGHT_TEXT} style={{ whiteSpace: "nowrap" }}>
              {isStock ? "Market Cap" : compareType === compareTypes.current ? "Market Cap" : "ATH MCap"}
            </SmallText>
            <SmallText>
              ${graphFormatter(
                isStock
                  ? coin2data.market_cap
                  : compareType === compareTypes.ath
                    ? (() => {
                      console.log('athMarketCap:', athMarketCap);
                      // If we get "coin-ds", we should ONLY use the fallback calculation
                      if (athMarketCap === "coin-ds") {
                        return coin2data.ath * coin2data.circSupply;
                      }
                      // Otherwise use athMarketCap if available, or fallback
                      return athMarketCap || (coin2data.ath * coin2data.circSupply);
                    })()
                    : coin2data.marketcap
              )}
            </SmallText>
          </Column>
        </FullRow>
      </StyledCompareResultInfoRow>
    </StyledCompareResult>
  );
}

export const StyledCompareResult = styled(Column)`
  gap: 1rem;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.LEVEL2};
  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
  border-radius: ${borderRads.MEDIUM};

  min-width: 27rem;
  width: 100%;

  .main-text {
    @media (max-width: 40rem) {
      font-size: 0.9rem !important;
    }
  }

  & > *:first-child {
    padding: ${paddings.MID};
    background-color: ${({ theme }) => theme.colors.LEVEL};
    border-radius: ${borderRads.MEDIUM};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (max-width: 50rem) {
    background-color: ${({ theme }) => theme.colors.LEVEL};
  }

  & > *:nth-child(2) {
    display: none;
  }

  @media (max-width: 62rem) {
    & > *:first-child {
      display: none;
    }

    & > *:nth-child(2) {
      display: block;
    }
    padding-top: 0.5rem;

    gap: 1.25rem;
  }

  @media (max-width: 40rem) {
    border-radius: ${borderRads.SMALL};

    min-width: auto;
    & > *:last-child {
      flex-direction: column;
    }
  }
`;

const StyledFutureResultNew = styled(Paragraph)`
  padding: ${paddings.MID} 1.25rem;
  background-color: ${({ theme }) => theme.colors.LEVEL};
  border-radius: ${borderRads.SMALL};
  align-items: center;
  justify-content: center;

  font-size: 1.75rem;
  position: relative;

  @media (max-width: 40rem) {
    background-color: rgba(255, 255, 255, 0.05);
    border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
    font-size: 1.7rem;
    padding: ${paddings.LOW} 1.25rem;

    & > *:first-child {
      top: -1.1rem !important;
    }
  }
`;

const StyledCompareResultInfoRow = styled(Row)`
  gap: 1rem;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: ${paddings.LOW};

  & > * {
    background-color: ${({ theme }) => theme.colors.LEVEL};
    padding: 1rem;
    border-radius: ${borderRads.SMALL};
    justify-content: space-between;
    max-width: 18rem;
    //make the last 2 children width 7rem
  }

  @media (max-width: 40rem) {
    & > * {
      max-width: 23rem;
      justify-content: start;
      //make last 2 children margin left 1.2rem
      & > *:nth-child(3),
      & > *:nth-child(4) {
        width: 4rem;
      }

      & > *:nth-child(3) {
        margin-left: 1rem !important;
      }

      & > *:nth-child(4) {
        margin-left: 2rem !important;
      }

      padding: 0.5rem 1rem;

      ${SmallText} {
        font-size: 0.8rem;

        &:nth-child(2) {
          font-size: 0.9rem;
        }
      }
    }
    img {
      width: 2rem !important;
    }
  }
`;
