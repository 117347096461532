import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import ErrorPage from "../pages/errorPage";
import Home from "../pages/home";
import Aquarium from "../pages/aquarium";
import Vote from "../pages/vote";
import Terminal from "../pages/terminal";
import AboutUs from "../pages/aboutUs";
import Blog from "../pages/blog";
import React from "react";
import RecentlyListed from "../pages/recentlyListed";
import { MostCompared, TopGainers, TopMarketCap } from "../pages/mostCompared";
import Promote from "../pages/promote";
import Halving from "../pages/halving";
import Dreamfolio from "../pages/dreamfolio";
import DedicatedCoinNew from "../pages/dedicatedCoinNew";
import CategoryList from "../pages/category";
import CommonHolders from "../components/dedicatedCoin/commonHolders";
import { defaultCoins } from "../components/compareSection/defaultCoins";
import HolderCompareWrapper from "../components/holderCompare/holderCompare";
import Compare from "../pages/compareNew";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "*",
        element: <ErrorPage />,
      },
      {
        path: "",
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "compare",
        element: <Compare />,
      },
      {
        path: "compare/:coin1/:coin2",
        element: <Compare />,
      },
      {
        path: "compare/:coin1/:coin2/:mode",
        element: <Compare />,
      },
      {
        path: "compare/:coin1/:coin2/:amountInput",
        element: <Compare />,
      },
      {
        path: "coin/:coinId",
        element: <DedicatedCoinNew pageMode={0} />,
      },
      {
        path: "recently-listed",
        element: <RecentlyListed />,
      },

      {
        path: "coins",
        element: <CategoryList />,
      },

      {
        path: "coins/:tag",
        element: <CategoryList />,
      },

      // {
      //   path: "aquarium",
      //   element: <Aquarium />,
      // },

      {
        path: "terminal/:coinId",
        element: <Terminal />,
      },
      // {
      //   path: "about-us",
      //   element: <AboutUs />,
      // },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "most-compared",
        element: <MostCompared />,
      },
      {
        path: "top-gainers",
        element: <TopGainers />,
      },
      {
        path: "top-market-cap",
        element: <TopMarketCap />,
      },
      {
        path: "coin/:coinId/roi",
        element: <DedicatedCoinNew roi={true} pageMode={1} />,
      },
      {
        path: "coin/:coinId/compare",
        element: <DedicatedCoinNew roi={true} pageMode={2} />,
      },
      {
        path: "not-found",
        element: <ErrorPage />,
      },
      {
        path: "promote",
        element: <Promote />,
      },
      {
        path: "halving",
        element: <Halving />,
      },
      {
        path: "halving/:coin",
        element: <Halving />,
      },
      {
        path: "dreamfolio",
        element: <Dreamfolio />,
      },
      {
        path: "holder-compare",
        element: <HolderCompareWrapper />,
      },
      {
        path: "holder-compare/:coin1/:coin2",
        element: <HolderCompareWrapper />,
      },
    ],
  },
]);
