import { Column, FullRow, Row } from "../containers/flexbox.styled"
import { Icon } from "../icon.styled"
import { BigText, Header, SmallText } from "../texts.styled"
import { RecommendedBadge, StyledListingCard, StyledPromoteCard } from "./cards.styled"
import { PrimaryButton, TransparentButton } from "../buttons/buttons.styled"
import { useState } from "react"
import { borderRads, textSizes } from "../sizes"
import { HideOnMobileWrapper, ShowOnMobileWrapper } from "../hideOnMobile"
import { IoIosTimer, IoMdClose } from "react-icons/io"
import { ClickAwayListener, Modal } from "@mui/base"
import { Backdrop, Box } from "@mui/material"
import { theme } from "../theme.styled"
import { FiExternalLink } from "react-icons/fi"
import { FaFileAlt, FaTelegramPlane } from "react-icons/fa"
import { GrMail } from "react-icons/gr"
import CopyButton from "../misc/copyButton"
import { BiCopy } from "react-icons/bi"

const DISCOUNT_ACTIVE = true

export default function ListingCard({ data }) {
  const roundToNearestFive = (num) => Math.round(num / 5) * 5
  const discountedPrice = roundToNearestFive(data.price * 0.75)
  return (
    <StyledListingCard isRecommended={data.isRecommended} isPremium={data.isPremium}>
      {DISCOUNT_ACTIVE && (
        <div
          style={{
            position: "absolute",
            top: "0.5rem",
            right: "0.5rem",
            backgroundColor: "#ff6b6b",
            color: "white",
            padding: "0.25rem 0.5rem",
            borderRadius: "0.25rem",
            fontWeight: "bold",
            fontSize: "0.875rem",
            transform: "rotate(5deg)",
            zIndex: 1,
          }}
        >
          25% OFF
        </div>
      )}

      <Row justify="space-between" style={{ marginTop: DISCOUNT_ACTIVE ? "1rem" : "0" }}>
        <Row justify="start" gap="0.5rem" align="center">
          <ShowOnMobileWrapper>
            <Icon className="headerIcon" size="5rem">
              {data.icon}
            </Icon>
          </ShowOnMobileWrapper>
          <Header>{data.name}</Header>
        </Row>
        <ShowOnMobileWrapper>
          <Row align="center" gap="0.5rem">
            {DISCOUNT_ACTIVE ? (
              <>
                <Header style={{ textDecoration: "line-through", opacity: 0.7, color: theme.colors.RED }}>${data.price}</Header>
                <Header>${discountedPrice}</Header>
              </>
            ) : (
              <Header>${data.price}</Header>
            )}
          </Row>
        </ShowOnMobileWrapper>
      </Row>

      <HideOnMobileWrapper>
        <Icon size="5rem">{data.icon}</Icon>
      </HideOnMobileWrapper>

      <Column gap="0.75rem" width="100%">
  {[1, 2, 3, 4].map(i => {
    const service = data[`service${i}`]
    const icon = data[`icon${i}`]
    return (
      <FullRow key={i}>
        <SmallText>{service}</SmallText>
        <Icon className="serviceIcon" size="1.2rem">{icon}</Icon>
      </FullRow>
    )
  })}
</Column>


      <HideOnMobileWrapper>
        <Row align="center" gap="0.5rem">
          {DISCOUNT_ACTIVE ? (
            <>
              <BigText style={{ textDecoration: "line-through", opacity: 0.7, color: theme.colors.RED }}>${data.price}</BigText>
              <BigText>${discountedPrice}</BigText>
            </>
          ) : (
            <BigText>${data.price}</BigText>
          )}
        </Row>
      </HideOnMobileWrapper>

      <ListingMenuModal />

      {data.isRecommended && (
        <RecommendedBadge>
          <SmallText weight={textSizes.fontWeights.LIGHT}>Recommended</SmallText>
        </RecommendedBadge>
      )}
    </StyledListingCard>
  )
}

export function PromotionCard({ data, ref }) {
  const roundToNearestFive = (num) => Math.round(num / 5) * 5
  const price = (p) => DISCOUNT_ACTIVE ? roundToNearestFive(p * 0.75) : p

  return (
    <StyledPromoteCard isRecommended={data.isRecommended}>
      {DISCOUNT_ACTIVE && (
      <div
        style={{
          position: "absolute",
          top: "0.5rem",
          right: "0.5rem",
          backgroundColor: "#ff6b6b",
          color: "white",
          padding: "0.25rem 0.5rem",
          borderRadius: "0.25rem",
          fontWeight: "bold",
          fontSize: "0.875rem",
          transform: "rotate(5deg)",
          zIndex: 1,
        }}
      >
        25% OFF
      </div>
    )}
      <Row style={{ marginTop: DISCOUNT_ACTIVE ? "1rem" : "0" }}>
        <Header style={{ width: "100%", textAlign: "center" }}>{data.name}</Header>
      </Row>
      <img ref={ref} src={data.image || "/placeholder.svg"} />
      <Column gap="0.5rem" width="100%">
        {[1, 2, 3].map(i => {
          const service = data[`service${i}`]
          const priceValue = data[`price${i}`]
          return (
          <FullRow key={i} style={{ justifyContent: "space-between", alignItems: "center" }}>
            <SmallText style={{ display: "flex", alignItems: "center", gap: "0.4rem", whiteSpace: "nowrap" }}>
             <Icon><IoIosTimer /></Icon>
             {service}
           </SmallText>
           <div style={{ textAlign: "right" }}>
              {DISCOUNT_ACTIVE ? (
                <>
                 <SmallText
                    weight={textSizes.fontWeights.SEMI_BOLD}
                   style={{ textDecoration: "line-through", opacity: 0.7, color: theme.colors.RED }}
                 >
                   ${priceValue}
                 </SmallText>
                  <SmallText weight={textSizes.fontWeights.SEMI_BOLD} style={{ marginLeft: "0.5rem"}}>
                    ${price(priceValue)}
                 </SmallText>
               </>
             ) : (
               <SmallText weight={textSizes.fontWeights.SEMI_BOLD}>${priceValue}</SmallText>
             )}
           </div>
          </FullRow>
          )
        })}
      </Column>
      <ListingMenuModal isPromote={true} />

      {data.isRecommended && (
        <RecommendedBadge>
          <SmallText weight={textSizes.fontWeights.LIGHT}>Recommended</SmallText>
        </RecommendedBadge>
      )}
    </StyledPromoteCard>
  )
}

export function ListingMenuModal({ isPromote }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div style={{ width: "100%" }}>
      <PrimaryButton
        style={{
          padding: "0.5rem 1rem",
          width: "6rem",
          margin: "0 auto",
          display: "block",
        }}
        onClick={() => handleOpen()}
        borderRadius="2rem"
      >
        Contact
      </PrimaryButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box sx={style} style={{ padding: "2rem", maxWidth: "90%" }}>
            <div style={{ position: "absolute", right: "1rem", top: "1rem" }}>
              <TransparentButton onClick={handleClose}>
                <Icon size="1.2rem"><IoMdClose /></Icon>
              </TransparentButton>
            </div>
            <Column align="center" gap="3rem">
              {!isPromote && (
                <Column gap="0.5rem">
                  <Row gap="0.5rem" align="center" justify="center">
                    <Icon><FaFileAlt /></Icon>
                    <SmallText>Listing Form</SmallText>
                  </Row>
                  <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfzBr3vudp7WAIhIR_Nyn4igldSN5LT3zwyptzcmulaTHNSeg/viewform?usp=sf_link">
                    <Row gap="0.5rem" className="form-link" style={{ padding: "0.5rem 1rem", borderRadius: borderRads.SMALL }}>
                      <SmallText color={theme.colors.LIGHT_TEXT}>Google Forms</SmallText>
                      <Icon color={theme.colors.LIGHT_TEXT}><FiExternalLink /></Icon>
                    </Row>
                  </a>
                </Column>
              )}
              <ContactSection />
            </Column>
          </Box>
        </ClickAwayListener>
      </Modal>
    </div>
  )
}

function ContactSection() {
  return (
    <>
      <Column gap="0.5rem">
        <Row gap="0.5rem" align="center" justify="center">
          <Icon><FaTelegramPlane /></Icon>
          <SmallText>Telegram</SmallText>
        </Row>
        <a target="_blank" href="https://t.me/CompareMarketCapListing">
          <Row className="form-link" gap="0.5rem" style={{ padding: "0.5rem 1rem", borderRadius: borderRads.SMALL }}>
            <SmallText color={theme.colors.LIGHT_TEXT}>t.me/CompareMarketCapListing</SmallText>
            <Icon color={theme.colors.LIGHT_TEXT}><FiExternalLink /></Icon>
          </Row>
        </a>
        <CopyButton text="@CompareMarketCapListing" isRight={true}>
          <Row gap="0.5rem" align="center" justify="center">
            <SmallText color={theme.colors.LIGHT_TEXT} size={textSizes.fontSizes.SMALLER}>Copy handle</SmallText>
            <Icon color={theme.colors.LIGHT_TEXT}><BiCopy /></Icon>
          </Row>
        </CopyButton>
      </Column>
      <Column gap="0.5rem">
        <Row gap="0.5rem" align="center" justify="center">
          <Icon><GrMail /></Icon>
          <SmallText>Mail</SmallText>
        </Row>
        <a href="mailto:contact@comparemarketcap.com">
          <Row className="form-link" gap="0.5rem" style={{ padding: "0.5rem 1rem", borderRadius: borderRads.SMALL }}>
            <SmallText color={theme.colors.LIGHT_TEXT}>contact@comparemarketcap.com</SmallText>
            <Icon color={theme.colors.LIGHT_TEXT}><FiExternalLink /></Icon>
          </Row>
        </a>
        <CopyButton text="contact@comparemarketcap.com" isRight={true}>
          <Row gap="0.5rem" align="center" justify="center">
            <SmallText color={theme.colors.LIGHT_TEXT} size={textSizes.fontSizes.SMALLER}>Copy address</SmallText>
            <Icon color={theme.colors.LIGHT_TEXT}><BiCopy /></Icon>
          </Row>
        </CopyButton>
      </Column>
    </>
  )
}

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.colors.OLDLEVEL,
  borderRadius: borderRads.SMALL,
  boxShadow: 24,
  zIndex: 1,
}
