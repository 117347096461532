import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { TrendsContext } from "../context";
import { useQuery } from "@tanstack/react-query";
import { getTagCoins, getTags, getTrending } from "../components/stream";
import {
  StyledTable,
  StyledTableWrapper,
} from "../components/tables/table.styled";
import { Column, FullRow, Row } from "../components/containers/flexbox.styled";
import { Icon } from "@mui/material";
import { FiTrendingUp } from "react-icons/fi";
import { Header, Paragraph, SmallText } from "../components/texts.styled";
import { HideOnMobileWrapper } from "../components/hideOnMobile";
import {
  add3Dots,
  getSmallNumber,
  graphFormatter,
  maxLittleNumber,
} from "../util/numberFormatter";
import { formatNumber } from "chart.js/helpers";
import TableSwitch from "../components/tables/tableSwitch";
import { borderRads, textSizes } from "../components/sizes";
import Loader from "../components/misc/loader";
import { Container } from "../components/containers/boxes.styled";
import SelectMenu from "../components/misc/selectMenu";
import PageHeader from "../components/misc/pageHeader";
import { toast } from "react-toastify";
import { RouteLink } from "../routing/routeLink";
import { theme } from "../components/theme.styled";

export default function CategoryList({}) {
  const navigate = useNavigate();

  const notify = (text) => toast(text || "There has been an error.");

  let { tag } = useParams();

  let formattedTag = tag;

  if (formattedTag) {
    formattedTag = decodeURIComponent(formattedTag);
  }

  function flattenDictWithDegrees(inputDict) {
    let result = [];
    for (let key in inputDict) {
      if (inputDict.hasOwnProperty(key)) {
        result.push({ item: key, degree: 0 });
        inputDict[key].forEach((value) => {
          result.push({ item: value, degree: 1 });
        });
      }
    }

    return result;
  }

  const [selectedTag, setSelectedTag] = useState(null);

  const [dropdownData, setDropdownData] = useState(null);

  const { data, status } = useQuery(
    ["category-page", formattedTag],
    () => getTags(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        //add {item: "All", degree: 0} to the beginning of the array
        let all = { item: "All Meme Coins", degree: 0 };
        data["All Meme Coins"] = [];
        //sort data alphabetically
        data = Object.keys(data)
          .sort()
          .reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
          }, {});

        let flatArray = flattenDictWithDegrees(data);

        //make for each item in the array, add an onClick function where it navigates to the coins/item
        flatArray.forEach((item) => {
          item.onClick = () => {
            // if item.item is "All Meme Coins", navigate to /coins/meme
            // if (item.item === "All Meme Coins") {
            //   navigate(`/coins/meme`);
            //   return
            // }
            navigate(`/coins/${item.item}`);
          };
        });

        setDropdownData(flatArray);

        if (formattedTag.toLowerCase() === "meme") {
          setSelectedTag("All Meme Coins");
          return;
        }

        let flatArrayItems = flattenDictWithDegrees(data).map((d) => d.item);
        let t = flatArrayItems.find((d) => {
          return d.toLowerCase() === formattedTag.toLowerCase();
        });
        if (t) {
          setSelectedTag(t);
        } else if (!tag) {
          setSelectedTag(flatArrayItems[0]);
        } else {
          notify(`${tag} could not be found.`);
          setSelectedTag(flatArrayItems[0]);
        }
      },
      onError: (error) => {
        notify();
      },
    }
  );

  const {
    data: tagCoins,
    status: tagStatus,
    refetch: fetchTagCoins,
  } = useQuery(
    ["tag-coins", formattedTag],
    () =>
      getTagCoins(
        formattedTag.toLowerCase() === "meme" ? "Meme" : formattedTag
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {},
      onError: (error) => {
        notify();
      },
    }
  );

  return (
    <Container key={tag}>
      {status === "success" && tagCoins ? (
        <>
          <StyledTableWrapper>
            <FullRow
              verticalOnMobile={true}
              justify={"space-between"}
              align={"end !important"}
            >
              <Column gap="1rem" align="start" width="100%">
                <Header>
                  Top {selectedTag === "All Meme Coins" ? "Meme" : selectedTag}{" "}
                  Coins
                </Header>
                <FullRow gap="0.5rem" style={{ maxWidth: "30rem" }}>
                  <Column
                    gap="0.5rem"
                    style={{
                      padding: "0.5rem",
                      width: "100%",
                      backgroundColor: theme.colors.LEVEL,
                      borderRadius: borderRads.SMALL,
                    }}
                  >
                    <SmallText color={({ theme }) => theme.colors.LIGHT_TEXT}>
                      Total Market Cap
                    </SmallText>
                    <Paragraph>
                      {graphFormatter(tagCoins.totalMarketcap)}
                    </Paragraph>
                  </Column>
                  <Column
                    gap="0.5rem"
                    style={{
                      padding: "0.5rem",
                      width: "100%",
                      backgroundColor: theme.colors.LEVEL,
                      borderRadius: borderRads.SMALL,
                    }}
                  >
                    <SmallText color={({ theme }) => theme.colors.LIGHT_TEXT}>
                      Total Volume
                    </SmallText>
                    <Paragraph>
                      {graphFormatter(tagCoins.totalVolume)}
                    </Paragraph>
                  </Column>
                </FullRow>
              </Column>
              {dropdownData && dropdownData.length > 1 && (
                <SelectMenu
                  options={dropdownData}
                  selected={selectedTag}
                  setSelected={setSelectedTag}
                  name="Category"
                />
              )}
            </FullRow>
            {tagStatus === "success" ? (
              <div style={{ overflowX: "auto", width: "100%" }}>
                <StyledTable>
                  <tr>
                    <th>#</th>
                    <th>Coin</th>
                    <th>Price</th>
                    <th>1h</th>
                    <th>1d</th>
                    <th>7d</th>
                    <th>Volume(24h)</th>
                    <th>Market Cap</th>
                  </tr>
                  {tagCoins.coins.map((item, index) => (
                    <tr
                      key={index}
                    >
                    <td>
                     <Link 
                        to={`/coin/${item.id}`} 
                       style={{ textDecoration: "none", color: "inherit" }}
                     >
                        <SmallText>{index + 1}</SmallText>
                      </Link>  
                    </td>
                    <td>
                     <Link 
                        to={`/coin/${item.id}`} 
                       style={{ textDecoration: "none", color: "inherit" }}
                     >
                        <Row gap={"0.7rem"}>
                          <img alt="logo" src={item.image} />
                          <HideOnMobileWrapper>
                            {add3Dots(item.coinName, 20)}
                          </HideOnMobileWrapper>
                          <SmallText
                            weight={textSizes.fontWeights.SEMI_BOLD}
                            color={({ theme }) => theme.colors.LIGHT_TEXT}
                          >
                            {item.symbol.toUpperCase()}
                          </SmallText>
                        </Row>
                      </Link>
                      </td>
                      {item.price < 0.001 ? (
                        <td>
                        <Link 
                           to={`/coin/${item.id}`} 
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <SmallText>
                            $0.0
                            <SmallText
                              size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
                            >
                              {getSmallNumber(item.price).zeroCount}
                            </SmallText>
                            {getSmallNumber(item.price).nonZeroDigits}
                          </SmallText>
                        </Link>
                        </td>
                      ) : (
                        <td>
                          <Link 
                             to={`/coin/${item.id}`} 
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                          <SmallText>${formatNumber(item.price)}</SmallText>
                          </Link>
                        </td>
                      )}
                      <td>
                        <Link 
                           to={`/coin/${item.id}`} 
                          style={{ textDecoration: "none", color: "inherit" }}
                         >
                        <SmallText
                          style={
                            item.change1H
                              ? item.change1H > 1
                                ? { color: "#82dd55" }
                                : { color: "#ff3f3f" }
                              : { color: "#ffffff" }
                          }
                        >
                          {item.change1H
                            ? `${graphFormatter(item.change1H, false, true)}%`
                            : "-"}
                        </SmallText>
                        </Link>
                      </td>
                      <td>
                         <Link 
                            to={`/coin/${item.id}`} 
                           style={{ textDecoration: "none", color: "inherit" }}
                        >
                        <SmallText
                          style={
                            item.change24H
                              ? item.change24H > 1
                                ? { color: "#82dd55" }
                                : { color: "#ff3f3f" }
                              : { color: "#ffffff" }
                          }
                        >
                          {item.change24H
                            ? `${graphFormatter(item.change24H, false, true)}%`
                            : "-"}
                        </SmallText>
                        </Link>
                      </td>
                      <td>
                       <Link 
                          to={`/coin/${item.id}`} 
                         style={{ textDecoration: "none", color: "inherit" }}
                       >
                        <SmallText
                          style={
                            item.change7D
                              ? item.change7D > 1
                                ? { color: "#82dd55" }
                                : { color: "#ff3f3f" }
                              : { color: "#ffffff" }
                          }
                        >
                          {item.change7D
                            ? `${graphFormatter(item.change7D, false, true)}%`
                            : "-"}
                        </SmallText>
                        </Link>
                      </td>
                      <td>
                       <Link 
                          to={`/coin/${item.id}`} 
                         style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <SmallText>
                          ${graphFormatter(item.volume24H, false, true)}
                        </SmallText>
                        </Link>
                      </td>
                      <td>
                       <Link 
                          to={`/coin/${item.id}`} 
                         style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <SmallText>
                          ${graphFormatter(item.marketcap, false, true)}
                        </SmallText>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </StyledTable>
              </div>
            ) : (
              <Loader />
            )}
          </StyledTableWrapper>
        </>
      ) : (
        <Loader height={"20rem"} />
      )}
    </Container>
  );
}
